import React from 'react'
import PropTypes from 'prop-types'
import './AnimatedLogo.css'

const AnimatedLogo = () => {
  return (
    <div style={{ width: '100%', height: 'auto' }}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1375.68 320.64"
      >
        <g>
          <path
            class="go-green"
            d="M1220,173.26c-1,1-1.91,1.86-2.89,2.67a31.93,31.93,0,0,1-4.14,3.22,8.07,8.07,0,0,1-12.08-4.79c-.16-.51-.31-1-.53-1.73-.6,1-1,1.83-1.6,2.59-2.65,3.58-6.07,5.74-10.67,5a7.74,7.74,0,0,1-6.77-6.52c-.06-.27-.12-.54-.26-1.2-.45.73-.66,1.13-.92,1.5a19.85,19.85,0,0,1-2,2.69c-6.09,6-13.27,4.32-16.75-3.35L1159.5,175c-1.45,1.24-2.82,2.62-4.38,3.71a8,8,0,0,1-9.82,0c-2.87-2.12-3.93-5.67-2.82-9.45a12,12,0,0,1,10.61-8.39c4-.21,7,1.91,8.93,6.54.43-.57.77-1.06,1.14-1.53a12.64,12.64,0,0,1,9.7-5,8.14,8.14,0,0,1,7.78,4.67c.3.55.52,1.16.89,2a12.52,12.52,0,0,1,6.86-5.77,12.93,12.93,0,0,1,3.95-.89,7.82,7.82,0,0,1,7.75,4.59c.28.56.49,1.14.84,2,.42-.58.71-1,1-1.42a13.38,13.38,0,0,1,9.9-5.15c3.94-.25,6.76,1.91,8.72,6.46.38-.48.7-.89,1-1.3a12.68,12.68,0,0,1,9.38-5.15,8.44,8.44,0,0,1,8.74,10.8c-1.21,4.58-6,8.6-10.69,8.62-3.68,0-6.17-2.28-8.11-5.2A18.59,18.59,0,0,1,1220,173.26Z"
          />
          <path
            class="go-green"
            d="M220.12,228.87a43.84,43.84,0,0,1-2.72,3.86,10.59,10.59,0,0,1-8.2,3.86,8.21,8.21,0,0,1-8.09-6.24c-.11-.44-.17-.89-.33-1.73-.53.84-.85,1.34-1.16,1.86a12.86,12.86,0,0,1-5.81,5.18c-4.47,2-9.36.18-11.8-4.32-.29-.55-.53-1.14-.87-1.88-.86.8-1.59,1.53-2.38,2.2a48.5,48.5,0,0,1-4.25,3.48,7.55,7.55,0,0,1-9,0,8.14,8.14,0,0,1-3.67-8.53A12,12,0,0,1,175.24,217a7.34,7.34,0,0,1,5.66,4.34c.3.63.53,1.29.91,2.24a12.25,12.25,0,0,1,6.11-5.38,17.21,17.21,0,0,1,5.06-1.24c3.63-.21,6.23,1.64,7.63,5,.18.44.33.89.61,1.61a13.19,13.19,0,0,1,7.56-6c4.73-1.63,9.7-.87,11.78,5.79.35-.4.65-.7.9-1a13.35,13.35,0,0,1,10.25-5.42,8,8,0,0,1,8.59,7.78c.46,6.66-6.44,13-13,11.79a7.9,7.9,0,0,1-6.65-5.82C220.49,230.14,220.34,229.6,220.12,228.87Z"
          />
          <path
            class="go-green"
            d="M670.16,228.72a13,13,0,0,1-1.07,1.79,25.48,25.48,0,0,1-3.56,3.83c-6.21,4.71-11.92,1.26-14.4-3.94l-.42-.91-2,1.73c-1.39,1.2-2.69,2.52-4.18,3.58-3.35,2.38-6.79,2.39-9.8.2a8.7,8.7,0,0,1-3.09-9.64,12.16,12.16,0,0,1,13-8.39,7.46,7.46,0,0,1,5.93,4.69c.26.57.46,1.17.76,2a13.38,13.38,0,0,1,7.85-6.16c5.3-1.76,9.24.16,11.23,5.42.05.13.19.22.41.49a13.71,13.71,0,0,1,8.28-6c5.88-1.72,9.54,1.28,10.69,5.27.07.23.2.43.39.86a13.58,13.58,0,0,1,8.45-6.17c6.7-1.88,11.94,2.66,11,9.56-.74,5.72-7,10.47-12.66,9.67-3.84-.54-6.17-2.93-7.36-7.77-.46.75-.81,1.33-1.17,1.9a12,12,0,0,1-7.2,5.59,8.36,8.36,0,0,1-10.64-6C670.51,229.84,670.37,229.39,670.16,228.72Z"
          />
          <path
            class="go-green"
            d="M490.81,111.27a13.64,13.64,0,0,1,8.35-6.28c3.41-1,6.52-.5,9,2.17s2.85,5.63,1.8,8.93a12.4,12.4,0,0,1-11.57,8.19,7.87,7.87,0,0,1-7.61-5.83l-.58-2c-.51.89-.86,1.61-1.3,2.26a11.64,11.64,0,0,1-9.59,5.5,7.82,7.82,0,0,1-8.13-6.39c-.08-.37-.12-.75-.24-1.5-.52.81-.86,1.31-1.17,1.83a12.59,12.59,0,0,1-5.87,5.11,8.89,8.89,0,0,1-11.9-4.44c-.24-.5-.42-1-.71-1.72-.93.84-1.74,1.6-2.59,2.31a48.65,48.65,0,0,1-4.33,3.4,7.52,7.52,0,0,1-8.79.09,8.28,8.28,0,0,1-3.66-8.33c.59-5.33,5.8-10,11.19-10.06a8.25,8.25,0,0,1,8.14,5.18c.19.42.35.86.62,1.54l1.06-1.39a14,14,0,0,1,8.39-5.18,8.19,8.19,0,0,1,9.37,5c.2.44.36.89.63,1.56.43-.58.75-1,1.11-1.48a13.35,13.35,0,0,1,9.41-5.14,7.74,7.74,0,0,1,8.1,4.76C490.23,109.91,490.45,110.47,490.81,111.27Z"
          />
          <path
            class="go-green"
            d="M1190.65,228.52c-.64,1.08-1,1.68-1.35,2.26a12.38,12.38,0,0,1-7.09,5.4c-5.17,1.49-9.47-1-10.81-6.18-.08-.3-.13-.62-.25-1.22l-1.25,1.88a12.84,12.84,0,0,1-8.47,5.79,7.78,7.78,0,0,1-9.44-7.14c-.59-6.31,4.46-12.21,10.62-12.42a8.35,8.35,0,0,1,8.51,5.36c.15.35.27.72.51,1.35.49-.68.81-1.2,1.2-1.65a13.48,13.48,0,0,1,10-5c3.59-.15,6,1.66,7.58,4.83.27.56.48,1.13.79,1.9a13.11,13.11,0,0,1,7.63-6.12c5.15-1.81,9.35.07,11.54,5.08.12.29.28.56.5,1a12.87,12.87,0,0,1,7.74-6.15c3.27-1.07,6.35-.77,8.93,1.68a8.47,8.47,0,0,1,2.3,9c-1.3,4.69-6.2,8.58-11.06,8.43-3.8-.11-6.26-2.47-8-5.67a8.29,8.29,0,0,1-.44-1.41c-.72.58-1.32,1.05-1.91,1.55-1.4,1.19-2.73,2.46-4.19,3.57C1198.36,239,1191.35,235.6,1190.65,228.52Z"
          />
          <path
            class="go-green"
            d="M1065.15,229a47.3,47.3,0,0,1-3.35,4.23,10.76,10.76,0,0,1-8.17,3.4,7.84,7.84,0,0,1-7.57-6.18c-.11-.44-.15-.9-.3-1.76l-1.19,1.82a13.06,13.06,0,0,1-7.44,5.7c-6,1.72-11-2.24-10.72-8.51a11.66,11.66,0,0,1,11.16-10.78,8.2,8.2,0,0,1,7.86,4.9c.28.55.5,1.13.81,1.86a13.34,13.34,0,0,1,8.07-6.25c5-1.59,9,.34,10.93,5.29.08.21.19.4.42.88a12.5,12.5,0,0,1,5.61-5.16,19.73,19.73,0,0,1,3.12-1.21,8.44,8.44,0,0,1,10.29,4.85c.19.42.36.85.59,1.39a17.59,17.59,0,0,1,2.72-3.07,23.71,23.71,0,0,1,5.4-3,8,8,0,0,1,8.68,1.74c2.6,2.43,3.12,5.51,2.3,8.82a11.85,11.85,0,0,1-10.92,8.61c-3.75,0-6.14-2.31-8-5.29a13.34,13.34,0,0,1-.73-1.78c-.74.65-1.36,1.21-2,1.75a51.64,51.64,0,0,1-4.64,3.77c-5.34,3.44-11.09,1.13-12.7-5C1065.29,229.72,1065.25,229.4,1065.15,229Z"
          />
          <path
            class="go-green"
            d="M795.75,223.72a13.41,13.41,0,0,1,9.12-6.62,8,8,0,0,1,9.71,5c.16.37.25.75.46,1.38.43-.51.76-.89,1.06-1.28a13.35,13.35,0,0,1,9.82-5.29,8,8,0,0,1,8.22,5c.19.43.35.89.61,1.55.4-.52.73-.91,1-1.32a12.76,12.76,0,0,1,8.61-5.18c6.47-1,11.26,4.66,9.47,11.06a11.91,11.91,0,0,1-11,8.5c-3.73,0-6.13-2.35-8-5.36a14.84,14.84,0,0,1-.73-1.7c-1,.88-1.85,1.7-2.77,2.45a36.76,36.76,0,0,1-4.35,3.35c-5,3-10.49.68-12.06-5-.13-.45-.23-.91-.4-1.6-.48.78-.84,1.39-1.21,2a12.79,12.79,0,0,1-5.48,4.87c-5.2,2.46-11.69.42-12.62-6.85-.47.69-.82,1.15-1.13,1.64a13,13,0,0,1-7.41,5.75c-6.12,1.81-11.16-2.23-10.8-8.63a11.71,11.71,0,0,1,10.82-10.63,8.33,8.33,0,0,1,8.33,5.27C795.29,222.58,795.46,223,795.75,223.72Z"
          />
          <path
            class="go-green"
            d="M221,111.4a15,15,0,0,1,8-6.19c5.07-1.82,9,.05,10.9,5.1l.31.84a3,3,0,0,0,.69-.55,14.42,14.42,0,0,1,6.35-4.94c4.94-2.29,10.47-1.34,12.69,5.49.41-.46.75-.81,1.07-1.19,2.7-3.18,6.06-5.23,10.3-5.37a7.81,7.81,0,0,1,7.92,6.63c1.1,6.47-4.65,13.26-11.22,13-3.57-.13-6-2.39-7.82-5.28a13.45,13.45,0,0,1-.8-1.79c-.72.63-1.33,1.18-2,1.72a50.48,50.48,0,0,1-4.19,3.58c-5.35,3.72-11.41,1.48-13.17-4.78-.09-.31-.15-.62-.29-1.2-.43.69-.75,1.18-1,1.68a12.68,12.68,0,0,1-5.53,5.15c-5.25,2.49-11.79.08-12.75-6.88l-1.21,1.94a12,12,0,0,1-8.19,5.75,8.28,8.28,0,0,1-9.93-8.78c.33-5.44,5.65-10.6,11.09-10.77a7.8,7.8,0,0,1,8.08,5.15C220.5,110.16,220.66,110.61,221,111.4Z"
          />
          <path
            class="go-green"
            d="M1104.34,117.13c-1,1-1.93,1.87-2.9,2.69a33.43,33.43,0,0,1-3.94,3.11,8,8,0,0,1-12.22-4.75c-.15-.51-.32-1-.51-1.61a36.9,36.9,0,0,1-2.34,3.48,10.87,10.87,0,0,1-8.52,4.22,8.17,8.17,0,0,1-8.21-6.67c-.06-.28-.11-.56-.23-1.23-.55.91-.9,1.5-1.27,2.09a12.09,12.09,0,0,1-8,5.63,8.29,8.29,0,0,1-10.07-8.82c.39-5.55,5.81-10.64,11.38-10.71a7.77,7.77,0,0,1,7.8,5.21c.18.44.34.89.58,1.54l1-1.21a15.1,15.1,0,0,1,9.08-5.4c3.79-.77,6.92,1,8.54,4.58.26.57.47,1.16.81,2l1.08-1.33a15.07,15.07,0,0,1,9.15-5.27c4-.72,7.25,1.23,8.86,5,.19.43.33.89.6,1.62.44-.54.76-.92,1.08-1.29,2.77-3.23,6.18-5.31,10.53-5.36a7.76,7.76,0,0,1,7.62,6.39c1.39,7.72-5.41,13.5-11.39,13.23-3.52-.16-6-2.46-7.73-5.4C1104.82,118.34,1104.65,117.81,1104.34,117.13Z"
          />
          <path
            class="go-green"
            d="M690.48,111.41a15.15,15.15,0,0,1,8.4-6.3c4.78-1.59,8.53.18,10.41,4.86.15.37.29.74.52,1.32.46-.57.81-1,1.19-1.47a14.86,14.86,0,0,1,9.68-5.2,7.6,7.6,0,0,1,8.18,4.95c.22.51.39,1,.68,1.79a14.74,14.74,0,0,1,7.63-6.08c3.21-1.22,6.33-1.16,9,1.28s3.32,5.53,2.43,9a11.81,11.81,0,0,1-10.35,8.67c-3.85.2-6.36-2.13-8.39-5.08a15,15,0,0,1-.93-2c-.73.64-1.35,1.21-2,1.75a59.81,59.81,0,0,1-4.59,3.84c-5.66,3.91-12.29-.4-12.73-5.37a6.81,6.81,0,0,0-.28-.9c-.44.7-.76,1.2-1.07,1.71a11.94,11.94,0,0,1-7.35,5.74,8.33,8.33,0,0,1-10.59-6c-.11-.38-.22-.76-.42-1.42-.46.78-.78,1.35-1.13,1.91a12,12,0,0,1-7.28,5.49c-6.54,1.73-11.92-3.37-10.6-10,1-4.83,6-9.15,10.91-9.3a7.88,7.88,0,0,1,8.08,5.16C690,110.16,690.18,110.6,690.48,111.41Z"
          />
          <path
            class="go-green"
            d="M719,173.26c-.79.71-1.46,1.37-2.18,2a43.9,43.9,0,0,1-4.44,3.65c-5,3.22-10.89,1-12.59-4.75-.13-.43-.23-.88-.42-1.59-.53.89-.89,1.58-1.33,2.23-2.21,3.31-5.2,5.43-9.27,5.59a8.2,8.2,0,0,1-8.38-6.18c-.1-.37-.17-.74-.36-1.52l-1.29,2a12,12,0,0,1-8.29,5.6,8.31,8.31,0,0,1-9.61-9.52,12.17,12.17,0,0,1,11-9.83,7.9,7.9,0,0,1,7.83,4.51c.34.63.59,1.3.94,2.09a13,13,0,0,1,9-6.32c4.75-1.06,8.15.76,9.93,5.26l.43,1.12a13.43,13.43,0,0,1,7.57-5.92,14.56,14.56,0,0,1,3.25-.73,7.89,7.89,0,0,1,8.33,5.16c.14.35.25.72.49,1.41.47-.65.76-1.08,1.08-1.48a13.21,13.21,0,0,1,9.69-5.09c5.88-.4,10,5.2,8.27,11.1-1.3,4.53-6,8.32-10.65,8.33-3.71,0-6.18-2.27-8.11-5.19A15.58,15.58,0,0,1,719,173.26Z"
          />
          <path
            class="go-green"
            d="M249.55,173.21c-.87.8-1.54,1.46-2.25,2.06a43.78,43.78,0,0,1-4.43,3.65c-5.12,3.27-11,.86-12.6-5-.08-.28-.18-.56-.37-1.19l-1.26,2a11.81,11.81,0,0,1-7.53,5.49,8.35,8.35,0,0,1-10.23-6c-.13-.42-.25-.85-.44-1.49-.48.81-.82,1.43-1.2,2a11.94,11.94,0,0,1-7.58,5.44,8.32,8.32,0,0,1-10-10.57,12.29,12.29,0,0,1,10.71-8.64,7.9,7.9,0,0,1,7.71,4.31,21.53,21.53,0,0,1,.94,2.17l1-1.29a13.3,13.3,0,0,1,10.85-5.17,7.22,7.22,0,0,1,6.69,4.31c.34.69.61,1.42,1,2.3a14.17,14.17,0,0,1,8.88-6.31c3.74-1.05,8.76.23,10.37,5.3.09.27.24.53.43.95a13.5,13.5,0,0,1,9.33-6.39,8.3,8.3,0,0,1,9.94,9.59c-.83,5.19-5.75,9.68-11,9.68-3.68,0-6.18-2.27-8.08-5.22A15.52,15.52,0,0,1,249.55,173.21Z"
          />
          <path
            class="go-green"
            d="M1210.83,111.43a14.61,14.61,0,0,1,7.87-6.15c6.85-2.56,12.59,2.06,11.63,9.3-.76,5.71-7,10.46-12.67,9.63-3.92-.57-6.33-3.07-7.31-7.79l-1.19,1.93A12.42,12.42,0,0,1,1202,124c-3.82,1.13-9.55-.59-10.79-6.12-.08-.38-.21-.76-.38-1.36-.46.81-.79,1.43-1.16,2a11.73,11.73,0,0,1-7.07,5.39c-6.58,1.78-12-3.15-10.79-9.85.86-4.91,6-9.35,11-9.48a7.87,7.87,0,0,1,8,5c.21.49.4,1,.7,1.72a15.49,15.49,0,0,1,8.23-6.16c4.9-1.67,8.67.09,10.6,4.9Z"
          />
          <path
            class="go-green"
            d="M95.44,223.63a13.44,13.44,0,0,1,7.74-6.14c5.56-1.9,10,.6,11.34,5.24a6.18,6.18,0,0,0,.39.77,13.15,13.15,0,0,1,8.28-6.13c3.08-.9,6.06-.86,8.5,1.55a8.75,8.75,0,0,1,2.23,8.86,12,12,0,0,1-9.52,8.74c-4.94.76-8.79-2.12-10-7.76-.45.66-.8,1.12-1.1,1.61a12.77,12.77,0,0,1-6,5.34c-4.47,1.91-9.35,0-11.73-4.47-.26-.49-.45-1-.78-1.78-.8.72-1.46,1.35-2.17,1.94A48.94,48.94,0,0,1,88,235.19a7.57,7.57,0,0,1-9.17-.2,8.22,8.22,0,0,1-3.3-8.68A12,12,0,0,1,88.73,217a7.34,7.34,0,0,1,5.74,4.23C94.79,221.83,95,222.52,95.44,223.63Z"
          />
          <path
            class="go-green"
            d="M815,116.4a15,15,0,0,1-1,1.65,23.7,23.7,0,0,1-3.29,3.75c-5.86,4.59-11.78,2.34-14.8-3.72l-.47-.86c-.6.52-1.16,1-1.73,1.49a63.05,63.05,0,0,1-5.19,4.23,7.41,7.41,0,0,1-9-.24,8.11,8.11,0,0,1-3.44-8.4c.83-5.19,6-9.6,11.31-9.74a7.85,7.85,0,0,1,8,5.24c.17.41.3.84.59,1.66a13.71,13.71,0,0,1,5.77-5.35c5.07-2.72,11.11-2.43,13.71,5.18a14.22,14.22,0,0,1,7.39-5.88c3.35-1.28,6.64-1.5,9.37,1.26s3.25,6.3,1.86,10a11.73,11.73,0,0,1-10.17,7.6,8.24,8.24,0,0,1-8.69-6.67C815.16,117.32,815.11,117,815,116.4Z"
          />
          <path
            class="go-green"
            d="M824.46,172.88a49.51,49.51,0,0,1-3.49,4.41,10.15,10.15,0,0,1-7.32,3.1,8.23,8.23,0,0,1-8.18-6.13c-.14-.46-.27-.91-.47-1.6-.45.74-.79,1.33-1.16,1.9a12,12,0,0,1-7.29,5.47c-6.38,1.64-11.62-3.14-10.6-9.64a11.87,11.87,0,0,1,10.91-9.51c3.94-.08,6.78,2.06,8.59,6.59a3,3,0,0,0,.67-.57c2.24-3.47,5.61-5.16,9.5-5.9a7.87,7.87,0,0,1,8.48,4.49c.31.64.55,1.32.9,2.19a13.64,13.64,0,0,1,8.9-6.44c6.33-1.56,11.55,3.26,10.6,9.75-.82,5.61-7.38,10.35-12.93,9.27-3.63-.71-6-2.81-6.79-6.54,0-.23-.09-.47-.15-.7C824.61,173,824.53,172.94,824.46,172.88Z"
          />
          <path
            class="go-green"
            d="M470.52,228.76c-.49.82-.83,1.43-1.21,2a12.45,12.45,0,0,1-5.74,4.92c-3.33,1.38-6.59,1.41-9.45-1.06s-3.26-5.8-2.4-9.24a11.67,11.67,0,0,1,11.18-8.53,7.71,7.71,0,0,1,7.41,4.74c.27.55.47,1.13.81,2a12.87,12.87,0,0,1,7-5.78,14.14,14.14,0,0,1,4.18-.89,8.29,8.29,0,0,1,8.3,10.13,12.23,12.23,0,0,1-11.21,9.54,7.92,7.92,0,0,1-8.38-6.07Z"
          />
          <path
            class="go-green"
            d="M1336.54,111.58a14.62,14.62,0,0,1,9.79-6.67,8,8,0,0,1,9.76,7,11.55,11.55,0,0,1-13.24,12.33c-3.59-.64-5.89-3.18-6.78-7.75-.51.85-.9,1.51-1.3,2.16-2.74,4.41-7.66,6.53-12.07,5.19a8.46,8.46,0,0,1-5.91-9.49,12.42,12.42,0,0,1,11.23-9.83,7.6,7.6,0,0,1,7.75,4.88C1336,110,1336.21,110.65,1336.54,111.58Z"
          />
          <path
            class="go-green"
            d="M960.36,111.56a14.56,14.56,0,0,1,8.29-6.28c3.18-1.09,6.18-.81,8.71,1.55s3.12,5.7,2.26,9.07A11.71,11.71,0,0,1,967,124.33c-3.74-.48-6.25-3.13-7.1-7.89-.54.93-.88,1.56-1.27,2.17-2.33,3.58-5.48,5.78-9.9,5.65a8.43,8.43,0,0,1-8-10.46A12.39,12.39,0,0,1,952,104.56a7.58,7.58,0,0,1,7.57,4.79C959.83,110,960,110.69,960.36,111.56Z"
          />
          <path
            class="go-green"
            d="M365.36,111.45a14,14,0,0,1,8-6.25c6.89-2.41,12.55,2.31,11.48,9.53-.83,5.6-7,10.24-12.55,9.5a8.24,8.24,0,0,1-7.13-6.49c-.08-.35-.13-.7-.27-1.43-.56.94-.9,1.54-1.27,2.13a11.9,11.9,0,0,1-7.95,5.68,8.48,8.48,0,0,1-9.94-10.28,12.37,12.37,0,0,1,11.19-9.27,7.61,7.61,0,0,1,7.62,4.72C364.81,109.87,365,110.48,365.36,111.45Z"
          />
          <path
            class="go-green"
            d="M321.66,139.62a13.38,13.38,0,0,1,8.21-6.32c6.93-2.13,12.52,3,10.92,10.11a11.73,11.73,0,0,1-10.5,9.09c-3.82.16-6.36-2.17-8.36-5.16a15.79,15.79,0,0,1-.9-1.93c-.8.73-1.47,1.38-2.19,2a48.31,48.31,0,0,1-5.07,4,7.46,7.46,0,0,1-9-.55,8.06,8.06,0,0,1-3.2-8.47,12,12,0,0,1,13.1-9.44,7.47,7.47,0,0,1,5.93,4.33C321,138,321.27,138.7,321.66,139.62Z"
          />
          <path
            class="go-green"
            d="M916.66,139.63a13.13,13.13,0,0,1,8-6.27c3.16-1,6.21-.9,8.75,1.51s3.12,5.46,2.3,8.82c-1.17,4.75-5.62,8.66-10.49,8.8-3.9.12-6.49-2.26-8.45-5.4a6.24,6.24,0,0,1-.36-.88,6.73,6.73,0,0,0-.48-.75c-.76.7-1.42,1.36-2.15,1.95a49.26,49.26,0,0,1-5.07,4,7.46,7.46,0,0,1-8.78-.45,8.08,8.08,0,0,1-3.39-8.4,12,12,0,0,1,13.19-9.63,7.48,7.48,0,0,1,5.9,4.37C916,138,916.28,138.74,916.66,139.63Z"
          />
          <path
            class="go-green"
            d="M1292.22,145.37c-.88.81-1.53,1.48-2.26,2.07a47.94,47.94,0,0,1-5.08,4,7.44,7.44,0,0,1-8.77-.49,8.08,8.08,0,0,1-3.35-8.42,12,12,0,0,1,13-9.61,7.49,7.49,0,0,1,6.12,4.43c.34.69.62,1.42,1,2.3a13.24,13.24,0,0,1,8.49-6.42c6.68-1.93,12.12,3.25,10.65,10.06a11.74,11.74,0,0,1-10.39,9.23c-3.85.26-6.37-2.09-8.43-5A14.19,14.19,0,0,1,1292.22,145.37Z"
          />
          <path
            class="go-green"
            d="M57.36,60.92c-1,1-1.78,1.76-2.62,2.48a41.24,41.24,0,0,1-4.27,3.47c-5.54,3.53-12.13,0-12.47-6.52-.34-6.41,6-12.49,12.4-11.75a8.18,8.18,0,0,1,6.74,4.76c.27.55.5,1.11.87,1.93a12.58,12.58,0,0,1,7.4-6,12,12,0,0,1,4.91-.59,8.31,8.31,0,0,1,6.64,11,12,12,0,0,1-9.58,8.42c-3.6.74-7.31-1.45-9.15-5.12C58,62.44,57.74,61.84,57.36,60.92Z"
          />
          <path
            class="go-green"
            d="M1158.68,132.82a8,8,0,0,1,8.19,7.42c.63,6.93-5.67,12.76-12.27,12.27a8.45,8.45,0,0,1-7.6-10A11.87,11.87,0,0,1,1158.68,132.82Z"
          />
          <path
            class="go-green"
            d="M841.39,132.82a8.08,8.08,0,0,1,8.31,8.08c.23,6.32-6,12.22-12.28,11.64a8.55,8.55,0,0,1-7.57-10.42A11.88,11.88,0,0,1,841.39,132.82Z"
          />
          <path
            class="go-green"
            d="M657.72,132.82a7.73,7.73,0,0,1,8,6.4c1.64,7.35-6.14,14.9-13.45,13a8.44,8.44,0,0,1-6.27-9.65A12.09,12.09,0,0,1,657.72,132.82Z"
          />
          <path
            class="go-green"
            d="M324.87,68.3A8.37,8.37,0,0,1,316.52,58a12.31,12.31,0,0,1,11.34-9.41c5.37-.15,9.22,4.37,8.39,9.86A12.06,12.06,0,0,1,324.87,68.3Z"
          />
          <path
            class="go-green"
            id="red-24"
            d="M196.39,141.25a11.72,11.72,0,0,1-11.83,11.31,8.57,8.57,0,0,1-7.72-11.25,12.12,12.12,0,0,1,12.92-8.4C193.71,133.43,196.41,136.82,196.39,141.25Z"
          />
          <path
            class="go-green"
            d="M1269.75,216.88c5.67,0,9.26,4.87,7.92,10.74a12,12,0,0,1-10.6,9c-6.23.38-10.58-5.31-8.7-11.39A12.19,12.19,0,0,1,1269.75,216.88Z"
          />
          <path
            class="go-green"
            d="M901.68,225.23a11.68,11.68,0,0,1-12.23,11.36c-5.56-.45-9-6.07-7.18-11.6A12.14,12.14,0,0,1,895,216.94C898.93,217.42,901.67,220.82,901.68,225.23Z"
          />
          <path
            class="go-green"
            d="M1021.49,143.85c.07-6.84,7.35-12.52,14-10.8a7.45,7.45,0,0,1,5.63,5.66c2.11,8.66-6.42,14.92-12.76,13.68A8.23,8.23,0,0,1,1021.49,143.85Z"
          />
          <path
            class="go-green"
            d="M306.69,225.39c-.05,6.34-6.12,11.82-12.39,11.18a8.59,8.59,0,0,1-7.14-11.29,12.19,12.19,0,0,1,13-8.32C304.07,217.52,306.72,221,306.69,225.39Z"
          />
          <path
            class="go-green"
            d="M901.47,115.73c0-6.6,7.3-12.44,13.73-11,4.47,1,7.14,5.83,5.92,10.73a12.07,12.07,0,0,1-10.75,8.83A8.34,8.34,0,0,1,901.47,115.73Z"
          />
          <path
            class="go-green"
            d="M306.47,115.72c0-6.6,7.33-12.44,13.75-11,4.46,1,7.13,5.84,5.91,10.74a12.07,12.07,0,0,1-10.76,8.82A8.35,8.35,0,0,1,306.47,115.72Z"
          />
          <path
            class="go-green"
            d="M1297.54,113.09a11.71,11.71,0,0,1-12.2,11.18,8.57,8.57,0,0,1-7.43-10.94,12.56,12.56,0,0,1,10.88-8.76C1294,104.35,1297.59,107.83,1297.54,113.09Z"
          />
          <path
            class="go-green"
            d="M379.89,144c0-6.77,7.07-12.45,13.64-11,3.88.85,6.51,4.82,6.05,9.13a12,12,0,0,1-11.35,10.43C383.45,152.65,379.86,149,379.89,144Z"
          />
          <path
            class="go-green"
            d="M724.41,144.17c0-6.79,6.83-12.53,13.48-11.18a7.29,7.29,0,0,1,5.74,5.21c2.55,7.84-5.24,15.49-12.62,14.19C727,151.68,724.44,148.38,724.41,144.17Z"
          />
          <path
            class="go-green"
            d="M986.39,132.82c5.22,0,8.86,4.25,8.15,9.56a12,12,0,0,1-11.09,10.17c-5.85.27-9.91-5.21-8.26-11.16A12.11,12.11,0,0,1,986.39,132.82Z"
          />
          <path
            class="go-green"
            d="M1351.07,144c0-6.68,6.84-12.26,13.42-11,4,.75,6.75,4.78,6.27,9.19a12,12,0,0,1-11.37,10.41C1354.62,152.64,1351,149,1351.07,144Z"
          />
          <path
            class="go-green"
            d="M1287.79,169.54c-.13,6.49-7.2,12.06-13.5,10.63a8.16,8.16,0,0,1-6.41-9.7,12.1,12.1,0,0,1,12.1-9.58A8.26,8.26,0,0,1,1287.79,169.54Z"
          />
          <path
            class="go-green"
            d="M308.42,160.88a8.26,8.26,0,0,1,8.18,8.76c-.18,6.18-6.8,11.65-12.89,10.67a8.21,8.21,0,0,1-6.92-10.17A12.06,12.06,0,0,1,308.42,160.88Z"
          />
          <path
            class="go-green"
            d="M911.6,169.47c-.07,6.48-7.13,12.11-13.43,10.72a8.17,8.17,0,0,1-6.49-9.65,12.1,12.1,0,0,1,12-9.66A8.26,8.26,0,0,1,911.6,169.47Z"
          />
          <path
            class="go-green"
            d="M1225.35,144.07c0-6.59,6.57-12.17,13.09-11.14,4.09.64,7.06,4.76,6.61,9.17a12,12,0,0,1-11.31,10.46A8.13,8.13,0,0,1,1225.35,144.07Z"
          />
          <path
            class="go-green"
            d="M365.08,228.18c0-6.69,6.7-12.36,13.27-11.19a8.19,8.19,0,0,1,6.4,8.86,11.9,11.9,0,0,1-11,10.74A8.06,8.06,0,0,1,365.08,228.18Z"
          />
          <path
            class="go-green"
            d="M1336.26,228.19c0-6.69,6.68-12.36,13.26-11.2,4,.71,6.81,4.67,6.39,9.09a12,12,0,0,1-10.74,10.51A8.1,8.1,0,0,1,1336.26,228.19Z"
          />
          <path
            class="go-green"
            d="M109.81,144c0-6.77,7-12.46,13.62-11,4.11.89,6.76,5.25,6,9.83a12,12,0,0,1-11,9.72C113.53,152.79,109.79,149.08,109.81,144Z"
          />
          <path
            class="go-green"
            d="M446.65,144a11.49,11.49,0,0,1,11.2-11.13,8.4,8.4,0,0,1,8.36,10.12,12.36,12.36,0,0,1-11.64,9.62C449.89,152.57,446.6,149,446.65,144Z"
          />
          <path
            class="go-green"
            d="M28.17,228.14a11.48,11.48,0,0,1,11.56-11.26,8.39,8.39,0,0,1,8.07,9.75,12.3,12.3,0,0,1-11.09,10C31.74,236.89,28.16,233.35,28.17,228.14Z"
          />
          <path
            class="go-green"
            d="M274.54,141.26a11.72,11.72,0,0,1-11.87,11.29,8.38,8.38,0,0,1-7.77-9.81,12.08,12.08,0,0,1,10.84-9.9C271,132.56,274.56,135.93,274.54,141.26Z"
          />
          <path
            class="go-green"
            d="M1119.53,141.37a11.74,11.74,0,0,1-12,11.18,8.36,8.36,0,0,1-7.69-9.66,12.1,12.1,0,0,1,10.68-10C1115.86,132.5,1119.58,136,1119.53,141.37Z"
          />
          <path
            class="go-green"
            d="M47.91,115.82c0-5.89,5.35-11.25,11.18-11.26a8.39,8.39,0,0,1,8.31,10.37,12.33,12.33,0,0,1-11.16,9.34C51.39,124.49,47.88,120.93,47.91,115.82Z"
          />
          <path
            class="go-green"
            d="M831.28,189a8.13,8.13,0,0,1,8.11,10.39,11.69,11.69,0,0,1-11.56,9,8.39,8.39,0,0,1-8.1-9.76A11.76,11.76,0,0,1,831.28,189Z"
          />
          <path
            class="go-green"
            d="M132.87,68.3a8.14,8.14,0,0,1-8.18-7.76c-.34-6.38,5.59-12.31,12-12,4.93.25,8.32,4.53,7.66,9.66A12.08,12.08,0,0,1,132.87,68.3Z"
          />
          <path
            class="go-green"
            d="M769.68,208.4a8.35,8.35,0,0,1-8.35-10.31,11.77,11.77,0,0,1,12.93-9,8.17,8.17,0,0,1,6.79,9.46A11.79,11.79,0,0,1,769.68,208.4Z"
          />
          <path
            class="go-green"
            d="M1020,208.4a8.35,8.35,0,0,1-8.22-10.23,11.78,11.78,0,0,1,13.11-9,8.19,8.19,0,0,1,6.61,9.59A11.79,11.79,0,0,1,1020,208.4Z"
          />
          <path
            class="go-green"
            d="M319.93,96.08a8.36,8.36,0,0,1-8.2-10.58,12.19,12.19,0,0,1,13.17-8.7,8,8,0,0,1,6.56,8.91A11.77,11.77,0,0,1,319.93,96.08Z"
          />
          <path
            class="go-green"
            d="M1114.57,59.78c0-6.15,6.18-11.74,12.29-11.2,4.81.42,8,4.68,7.36,9.7a12.14,12.14,0,0,1-10.54,10A8.15,8.15,0,0,1,1114.57,59.78Z"
          />
          <path
            class="go-green"
            id="green-25"
            d="M300.19,208.4A8.41,8.41,0,0,1,292,197.17a11.83,11.83,0,0,1,12.68-8c4.89.69,7.87,5.51,6.57,10.62A11.8,11.8,0,0,1,300.19,208.4Z"
          />
          <path
            class="go-green"
            d="M895.05,208.4A8.41,8.41,0,0,1,887,197.28a11.86,11.86,0,0,1,12.85-8.15c4.87.78,7.78,5.65,6.4,10.74A11.79,11.79,0,0,1,895.05,208.4Z"
          />
          <path
            class="go-green"
            id="red-25"
            d="M1145.65,208.4a8.41,8.41,0,0,1-8.15-11.23,11.85,11.85,0,0,1,12.69-8c4.88.69,7.86,5.51,6.56,10.62A11.79,11.79,0,0,1,1145.65,208.4Z"
          />
          <path
            class="go-green"
            d="M1271.37,208.4a8.41,8.41,0,0,1-8.15-11.23,11.84,11.84,0,0,1,12.69-8c4.88.69,7.86,5.51,6.56,10.62A11.8,11.8,0,0,1,1271.37,208.4Z"
          />
          <path
            class="go-green"
            id="green-27"
            d="M62.52,141.37c.06,6.25-6.42,11.77-12.78,11.12-4.12-.42-7-4-7-8.39a11.67,11.67,0,0,1,11.12-11.28A8.22,8.22,0,0,1,62.52,141.37Z"
          />
          <path
            class="go-green"
            d="M134.49,112.9a11.71,11.71,0,0,1-12.06,11.37,8.32,8.32,0,0,1-7.65-9c.41-5.36,5.63-10.42,11-10.65A8,8,0,0,1,134.49,112.9Z"
          />
          <path
            class="go-green"
            d="M1036.52,169.28c.3,6.11-6.23,11.87-12.75,11a8,8,0,0,1-7.14-8.71c.3-6.54,7.24-11.94,13.42-10.45A8.2,8.2,0,0,1,1036.52,169.28Z"
          />
          <path
            class="go-green"
            d="M461.52,59.81c0-6.85,7-12.57,13.32-11a8.43,8.43,0,0,1,6.23,9.91A12.54,12.54,0,0,1,471,68.19C465.56,69,461.49,65.36,461.52,59.81Z"
          />
          <path
            class="go-green"
            id="green-24"
            d="M441.79,172.18a11.68,11.68,0,0,1,10.86-11.29A8.54,8.54,0,0,1,461,172.35a12.61,12.61,0,0,1-11.66,8.05C445,180.24,441.78,176.8,441.79,172.18Z"
          />
          <path
            class="go-green"
            d="M61.08,96.08c-4.52.31-8.88-3.81-8.19-9.55a11.65,11.65,0,0,1,11-9.81c5.95-.07,9.81,4.8,8.37,10.58A11.84,11.84,0,0,1,61.08,96.08Z"
          />
          <path
            class="go-green"
            d="M116.45,160.88a8.33,8.33,0,0,1,8.14,9.71,12,12,0,0,1-11.42,9.81,8.38,8.38,0,0,1-8-10.62A12.32,12.32,0,0,1,116.45,160.88Z"
          />
          <path
            class="go-green"
            d="M108.11,208.4c-4.32.29-8.62-3.57-8.18-9.13A11.62,11.62,0,0,1,110.83,189c6.19-.14,10.13,4.9,8.48,10.86A11.82,11.82,0,0,1,108.11,208.4Z"
          />
          <path
            class="go-green"
            d="M1109.51,197.13c0,6.73-6.64,12.36-13.25,11.16-4.15-.75-7.09-5.16-6.39-9.56a11.69,11.69,0,0,1,11.88-9.68A8,8,0,0,1,1109.51,197.13Z"
          />
          <path
            class="go-green"
            id="green-23"
            d="M1118,96.08c-4.86.43-10-4.46-7.85-11.32a11.94,11.94,0,0,1,11.32-8,8,8,0,0,1,7.91,9.06A11.78,11.78,0,0,1,1118,96.08Z"
          />
          <path
            class="go-green"
            d="M465,96.08c-4.49.44-9.86-3.9-8-11.09a11.57,11.57,0,0,1,11.56-8.26,8.14,8.14,0,0,1,7.78,9A11.8,11.8,0,0,1,465,96.08Z"
          />
          <path
            class="go-green"
            d="M253,208.4a8.2,8.2,0,0,1-8.13-9.18c.55-5.21,5.59-10,10.78-10.17,6.27-.25,10.32,4.9,8.59,10.94A11.81,11.81,0,0,1,253,208.4Z"
          />
          <path
            class="go-green"
            d="M444.73,208.4c-5,.27-8.57-4.39-7.92-9.48a11.62,11.62,0,0,1,12.42-9.84c5.23.5,8.47,5.67,6.86,10.94A12.22,12.22,0,0,1,444.73,208.4Z"
          />
          <path
            class="go-green"
            d="M503.21,208.4c-4.52.26-8.29-3.76-7.91-9.07A11.61,11.61,0,0,1,506.39,189c6.16-.07,10.05,5.15,8.21,11A12.2,12.2,0,0,1,503.21,208.4Z"
          />
          <path
            class="go-green"
            d="M57.67,169.58A8.84,8.84,0,0,1,55.91,175a12.48,12.48,0,0,1-9,5.39,8,8,0,0,1-8.92-9,11.91,11.91,0,0,1,8.91-10.14A8.48,8.48,0,0,1,57.67,169.58Z"
          />
          <path
            class="go-green"
            d="M41,208.4c-4.79.38-9.42-4.38-7.58-11.1A11.53,11.53,0,0,1,45,189.05a8.13,8.13,0,0,1,7.64,9.78A12.12,12.12,0,0,1,41,208.4Z"
          />
          <path
            class="go-green"
            d="M378,208.4c-5.5,0-9.22-5-7.9-10.59A12,12,0,0,1,381.54,189a8.14,8.14,0,0,1,8,9.52A11.84,11.84,0,0,1,378,208.4Z"
          />
          <path
            class="go-green"
            id="red-23"
            d="M722.47,208.4c-5.5,0-9.21-5-7.87-10.6A12,12,0,0,1,726.08,189a8.15,8.15,0,0,1,7.95,9.53A11.84,11.84,0,0,1,722.47,208.4Z"
          />
          <path
            class="go-green"
            d="M1094.9,172c.09-6.72,7-12.37,13.25-10.81a8.44,8.44,0,0,1,6.27,10.09,12.05,12.05,0,0,1-11,9.15A8.25,8.25,0,0,1,1094.9,172Z"
          />
          <path
            class="go-green"
            d="M1349.14,208.4c-5.5,0-9.22-5-7.88-10.6a12,12,0,0,1,11.47-8.76,8.16,8.16,0,0,1,8,9.53A11.85,11.85,0,0,1,1349.14,208.4Z"
          />
          <path
            class="go-green"
            d="M394.56,169.25c.07,6.77-7.31,12.62-13.65,10.82a8.24,8.24,0,0,1-5.87-9.66,12.23,12.23,0,0,1,10.73-9.51A8.14,8.14,0,0,1,394.56,169.25Z"
          />
          <path
            class="go-green"
            id="green-26"
            d="M1365.74,169.26c.06,6.78-7.33,12.61-13.67,10.8a8.22,8.22,0,0,1-5.84-9.67A12.21,12.21,0,0,1,1357,160.9,8.13,8.13,0,0,1,1365.74,169.26Z"
          />
        </g>
      </svg>
    </div>
  )
}

AnimatedLogo.propTypes = {
  //   href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  options: PropTypes.object,
  className: PropTypes.string,
  toleranceElement: PropTypes.string,
}

export default AnimatedLogo
